// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Images from "../../utils/Images";

// Local Imports
import CText from "../../common/CText";

interface QualityTileProps {
  title?: string;
  subtitle?: string;
  image?: string;
  leftOrRight?: "left" | "right";
  sx?: SxProps;
}

const QualityTile: FC<QualityTileProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "85%",
        mb: 4,
        ...props.sx,
      }}
    >
      {props.leftOrRight === "left" && (
        <Box
          component={"img"}
          src={props.image}
          sx={{
            width: 500,
            mt: 8,
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 0.8,
        }}
      >
        <CText
          fontSize={32}
          fontWeight={600}
          color={Colors.White}
          text={props.title}
          sx={{
            textAlign: props.leftOrRight === "left" ? "end" : "start",
          }}
        />

        <CText
          fontSize={28}
          fontWeight={400}
          color={Colors.Grey2}
          text={props.subtitle}
          sx={{
            mt: 2,
            textAlign: props.leftOrRight === "left" ? "end" : "start",
          }}
        />
      </Box>
      {props.leftOrRight === "right" && (
        <Box
          component={"img"}
          src={props.image}
          sx={{
            width: 500,
            mt: 8,
          }}
        />
      )}
    </Box>
  );
};

export default QualityTile;
