// React Imports
import React, { FC } from "react";

// MUI Imports
import { SxProps, Typography } from "@mui/material";
import Colors from "../../utils/Colors";

// Functional Imports

// Local Imports

interface CTextProps {
  text?: string | number;
  fontSize?: number;
  color?: string;
  fontWeight?: number;
  sx?: SxProps;
  fontFamily?: string;
}

const CText: FC<CTextProps> = (props) => {
  return (
    <Typography
      fontSize={props.fontSize}
      color={props.color}
      fontWeight={props.fontWeight}
      fontFamily={props.fontFamily}
      sx={props.sx}
    >
      {props.text}
    </Typography>
  );
};

export default CText;

CText.defaultProps = {
  fontSize: 14,
  fontWeight: 400,
  fontFamily: "Inter",
  color: Colors.White,
};
