// React Imports
import { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { Routes, Route, Link, Navigate } from "react-router-dom";
import PATHS from "./Paths";

// Screens
import Dashboard from "../components/Dashboard";
import UserList from "../components/UserList";
import Transactions from "../components/Transactions";
import User from "../components/User";
import UserAdList from "../components/UserAdList";
import ViewAd from "../components/ViewAd";
import ViewReport from "../components/ViewReport";
import Reports from "../components/Reports";
import LandingPage from "../components/LandingPage";
import AccountDelete from "../components/AccountDelete";
import PrivacyPolicy from "../components/PrivacyPolicy";

interface RoutePathProps {}

const Admin: FC<RoutePathProps> = (props) => {
  return (
    <Routes>
      <Route path={PATHS.DASHBOARD} element={<Dashboard />}>
        <Route
          path={PATHS.DASHBOARD + PATHS.TRANSACTIONS}
          element={<Transactions />}
        />
        <Route
          path={PATHS.DASHBOARD + PATHS.USER_LIST}
          element={<UserList />}
        />
        <Route path={PATHS.DASHBOARD + PATHS.USER} element={<User />} />
        <Route
          path={PATHS.DASHBOARD + PATHS.ADS_USER}
          element={<UserAdList />}
        />
        <Route path={PATHS.DASHBOARD + PATHS.AD} element={<ViewAd />} />
        <Route path={PATHS.DASHBOARD + PATHS.REPORT} element={<ViewReport />} />
        <Route path={PATHS.DASHBOARD + PATHS.REPORTS} element={<Reports />} />
      </Route>
    </Routes>
  );
};

const NoAuth: FC<RoutePathProps> = (props) => {
  return (
    <Routes>
      <Route path={PATHS.SLASH} element={<LandingPage />} />
      <Route path={PATHS.ACCOUNT_DELETE} element={<AccountDelete />} />
      <Route path={PATHS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path="*" element={<Navigate to={PATHS.SLASH} />} />
    </Routes>
  );
};

const RoutePath = {
  Admin,
  NoAuth,
};

export default RoutePath;
