// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

// Functional Imports
import Functions from "../../utils/Functions";
import API from "../../api/API";

// Local Imports

interface ReportsProps {}

const Reports: FC<ReportsProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getReports().then((response) => {
      setDataLoading(false);
      setReports(response.data);
    });
  }, []);

  if (dataLoading) {
    return (
      <Box
        sx={{
          width: "80%",
          pt: 2,
          px: 2,
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Loading...
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "80%",
          height: "90vh",
          pt: 2,
          px: 2,
          overflowY: "scroll",
        }}
      >
        <Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Reports
          </Typography>

          <DataGrid
            sx={{
              mt: 2,
            }}
            rows={reports}
            columns={columns}
            loading={dataLoading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            getRowId={(row) => row._id}
            pageSizeOptions={[5]}
            filterMode="client"
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    );
  }
};

export default Reports;

const columns: GridColDef[] = [
  { field: "_id", headerName: "ID", width: 90 },
  {
    field: "reason",
    headerName: "Reason",
    width: 150,
  },
  {
    field: "reasonType",
    headerName: "Reason Type",
    width: 150,
  },
  {
    field: "Ad",
    valueGetter: (params) => params.row?.ad?._id,
    headerName: "Ad",
    width: 150,
  },
  {
    field: "Ad Poster",
    valueGetter: (params) => params.row?.user?._id,
    headerName: "Ad Poster",
    width: 150,
  },
  {
    field: "Reporter",
    valueGetter: (params) => params.row?.reporter?._id,
    headerName: "Reporter",
    width: 150,
  },
  {
    field: "createdAt",
    valueGetter: (params) =>
      Functions.DateFunctions.showDateInfo(params.row?.createdAt),
    headerName: "Created At",
    width: 150,
  },
];