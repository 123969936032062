// React Imports
import React, { FC } from "react";

// MUI Imports
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  List,
  Select,
  MenuItem,
} from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import PaidIcon from "@mui/icons-material/Paid";
import GroupIcon from "@mui/icons-material/Group";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import BugReportIcon from "@mui/icons-material/BugReport";

// Functional Imports
import Colors from "../../utils/Colors";
import SideBarTile from "./SideBarTile";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Outlet } from "react-router-dom";

// Local Imports

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = (props) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <AppBar
        sx={{
          position: "relative",
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            SCNDSPAC
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "20%",
            backgroundColor: Colors.White,
          }}
        >
          <List>
            <SideBarTile
              label="Dashboard"
              selected={true}
              icon={<GridViewIcon />}
            />
            <SideBarTile label="Transactions" icon={<PaidIcon />} />
            <SideBarTile label="Users" icon={<GroupIcon />} />
            <SideBarTile label="Ads" icon={<AdUnitsIcon />} />
            <SideBarTile label="Reports" icon={<BugReportIcon />} />
          </List>
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
