// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, Typography, Select, MenuItem } from "@mui/material";

// Functional Imports
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface TransactionsProps {}

const Transactions: FC<TransactionsProps> = (props) => {
  return (
    <Box
      sx={{
        width: "80%",
        pt: 2,
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Transactions
        </Typography>

        <Select
          size="small"
          value={10}
          sx={{
            mr: 2,
          }}
          onChange={() => {}}
        >
          <MenuItem value={10}>Status Flag 1</MenuItem>
          <MenuItem value={20}>Status Flag 2</MenuItem>
          <MenuItem value={30}>Status Flag 3</MenuItem>
        </Select>

        <Select size="small" value={10} onChange={() => {}}>
          <MenuItem value={10}>Date 1</MenuItem>
          <MenuItem value={20}>Date 2</MenuItem>
          <MenuItem value={30}>Date 3</MenuItem>
        </Select>
      </Box>
      <DataGrid
        sx={{
          mt: 2,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        filterMode="client"
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default Transactions;

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "title",
    headerName: "Title",
    width: 150,
  },
  {
    field: "purchaseDate",
    headerName: "Purchase Date",
    width: 150,
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
    width: 110,
  },
  {
    field: "paidBy",
    headerName: "Paid By",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 110,
  },
  {
    field: "actions",
    headerName: "Actions",
    type: "number",
    width: 110,
  },
];

const rows = [
  {
    id: 1,
    title: "title",
    purchaseDate: "purchaseDate",
    amount: "amount",
    paidBy: "paidBy",
    status: "status",
    actions: "actions",
  },
];
