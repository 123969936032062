import axios from "axios";
import ENDPOINTS from "./ENDPOINTS";

const JWTToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YWQ4OTNiZmQxMzU1MWYyMzZlNzM4NCIsIm1vYmlsZU51bWJlciI6Ijc3OTUxODAyOTIiLCJpYXQiOjE3MDc1ODQ5ODIsImV4cCI6MTcxMjc2ODk4Mn0.tCu0wI6oNGFKVfnvoiFnctKf48IMr6A6DBhpBQ1lANs";

const login = async (id: any, password: any) => {
  let data = JSON.stringify({
    id: id,
    password: password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.LOGIN,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      alert(error.response?.data?.message);
    });
};

const forgotPassword = async (mobileNumber: any, otp: any) => {
  let data = JSON.stringify({
    mobileNumber: mobileNumber,
    otp: otp,
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.VERIFY_OTP,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const userList = async (searchText: any) => {
  let url = ENDPOINTS.ADMIN.USER_LIST;

  if (searchText !== "") {
    url = url + "?search=" + searchText;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getUser = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_USER + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const toggleAdminAllow = async (id: any) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.ALLOW_USER + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const adsPostedByUser = async (userId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.ADS_POSTED + "?userId=" + userId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAd = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_AD + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getReport = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.REPORT + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getReports = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.ADMIN.REPORTS +
      "?adId=65a88339c4e44e16d108ff88&userId=65a1825850af55f78e7d0967",
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const API = {
  login,
  forgotPassword,
  userList,
  getUser,
  toggleAdminAllow,
  adsPostedByUser,
  getAd,
  getReport,
  getReports,
};

export default API;
