// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Typography } from "@mui/material";

// Functional Imports
import Functions from "../../utils/Functions";
import API from "../../api/API";

// Local Imports
import LabelValue from "../../common/LabelValue";

interface ViewAdProps {}

const ViewAd: FC<ViewAdProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [ad, setAd] = useState({
    _id: "",
    location: {
      state: "",
      city: "",
      area: "",
    },
    adCategory: {
      category: "",
      subCategory: "",
    },
    info: {
      brand: "",
      title: " ",
      year: "",
      price: 0,
    },
    details: {
      attributes: [],
      description: "",
    },
    views: 0,
    contactPreference: "",
    status: "",
    step: 0,
    images: [],
    createdAt: "",
    user: {
      _id: "",
      name: "",
    },
    favouritesCount: 0,
    reportsCount: 0,
  });

  useEffect(() => {
    setDataLoading(true);
    API.getAd("65a1670f50af55f78e7d0960").then((response) => {
      setDataLoading(false);
      setAd(response.ad);
    });
  }, []);

  if (dataLoading) {
    return (
      <Box
        sx={{
          width: "80%",
          pt: 2,
          px: 2,
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Loading...
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "80%",
          height: "90vh",
          pt: 2,
          px: 2,
          overflowY: "scroll",
        }}
      >
        <Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            View Ad
          </Typography>

          <LabelValue label="ID" value={ad._id} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Location</Typography>

          <LabelValue label="State" value={ad.location?.state} />
          <LabelValue label="City" value={ad.location?.city} />
          <LabelValue label="Area" value={ad.location?.area} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Category</Typography>
          <LabelValue label="Category" value={ad.adCategory?.category} />
          <LabelValue label="Sub-category" value={ad.adCategory?.subCategory} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Info</Typography>
          <LabelValue label="Brand" value={ad.info?.brand} />
          <LabelValue label="Title" value={ad.info?.title} />
          <LabelValue label="Year" value={ad.info?.year} />
          <LabelValue label="Price" value={ad.info?.price} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Images</Typography>
          {ad.images?.map((item) => (
            <Box
              component={"img"}
              src={item}
              sx={{ height: 200, my: 2, mr: 4 }}
            />
          ))}

          <Typography sx={{ fontSize: 18, mt: 2 }}>Details</Typography>
          <LabelValue label="Description" value={ad.details?.description} />
          {ad.details?.attributes?.map((item: any) => (
            <LabelValue label={item.label} value={item.value} />
          ))}

          <Typography sx={{ fontSize: 18, mt: 2 }}>Other</Typography>
          <LabelValue label="Views" value={ad.views} />
          <LabelValue label="Contact Preferance" value={ad.contactPreference} />
          <LabelValue label="Status" value={ad.status} />
          <LabelValue
            label="Created At"
            value={Functions.DateFunctions.showDateInfo(ad.createdAt)}
          />
          <LabelValue label="Favourites Count" value={ad.favouritesCount} />
          <LabelValue label="Reports Count" value={ad.reportsCount} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Ad Poster</Typography>
          <LabelValue label="ID" value={ad.user?._id} />
          <LabelValue label="Name" value={ad.user?.name} />
        </Box>
      </Box>
    );
  }
};

export default ViewAd;
