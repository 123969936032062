const Colors = {
  White: "#FFF",
  Black: "#000",

  Grey: "#BBB",
  Grey2: "#959595",
  Grey3: "#141414",

  Gold: "#F7D553",
};

export default Colors;
