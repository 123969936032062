// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

// Functional Imports
import API from "../../api/API";
import Functions from "../../utils/Functions";

// Local Imports

interface UserAdListProps {}

const UserAdList: FC<UserAdListProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [adList, setAdList] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.adsPostedByUser("65a13e958df5e35d8d3823a3").then((response) => {
      setDataLoading(false);
      setAdList(response.data);
    });
  }, []);

  if (dataLoading) {
    return (
      <Box
        sx={{
          width: "80%",
          pt: 2,
          px: 2,
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Loading...
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "80%",
          height: "90vh",
          pt: 2,
          px: 2,
          overflowY: "scroll",
        }}
      >
        <Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Ad List - User
          </Typography>

          <DataGrid
            sx={{
              mt: 2,
            }}
            rows={adList}
            columns={columns}
            loading={dataLoading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            getRowId={(row) => row._id}
            pageSizeOptions={[5]}
            filterMode="client"
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    );
  }
};

export default UserAdList;

const columns: GridColDef[] = [
  { field: "_id", headerName: "ID", width: 90 },
  {
    field: "info.title",
    valueGetter: (params) => params.row?.info?.title,
    headerName: "Title",
    width: 150,
  },
  {
    field: "info.price",
    valueGetter: (params) => params.row?.info?.price,
    headerName: "Price",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "adCategory.subCategory",
    valueGetter: (params) => params.row?.adCategory?.subCategory,
    headerName: "Subcategory",
    width: 150,
  },
  {
    field: "location.city",
    valueGetter: (params) => params.row?.location?.city,
    headerName: "City",
    width: 150,
  },
  {
    field: "createdAt",
    valueGetter: (params) =>
      Functions.DateFunctions.showDateInfo(params.row?.createdAt),
    headerName: "Created At",
    width: 150,
  },
];
