const PATHS = {
  // Old Routes
  SLASH: "/",
  DASHBOARD: "/dashboard",
  TRANSACTIONS: "/transactions",
  USER_LIST: "/user-list",
  USER: "/user",
  ADS_USER: "/ads-user",
  AD: "/ad",
  REPORT: "/report",
  REPORTS: "/reports",

  // New Routes
  LANDING_PAGE: "/landing-page",
  ACCOUNT_DELETE: "/account-delete",
  PRIVACY_POLICY: "/privacy-policy",
};

export default PATHS;
