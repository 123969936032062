// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Typography } from "@mui/material";

// Functional Imports
import Functions from "../../utils/Functions";
import API from "../../api/API";

// Local Imports
import LabelValue from "../../common/LabelValue";

interface ViewReportProps {}

const ViewReport: FC<ViewReportProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [report, setReport] = useState({
    _id: "",
    reason: "",
    reasonType: "",
    status: "",
    createdAt: "",
    ad: {
      _id: "",
      info: {
        title: "",
      },
    },
    user: {
      _id: "",
      name: "",
    },
    reporter: {
      _id: "",
      name: "",
    },
  });

  useEffect(() => {
    setDataLoading(true);
    API.getReport("65aea55d338d2c80dc38099c").then((response) => {
      setDataLoading(false);
      setReport(response.report);
    });
  }, []);

  if (dataLoading) {
    return (
      <Box
        sx={{
          width: "80%",
          pt: 2,
          px: 2,
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Loading...
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "80%",
          height: "90vh",
          pt: 2,
          px: 2,
          overflowY: "scroll",
        }}
      >
        <Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            View Report
          </Typography>
          <LabelValue label="ID" value={report._id} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Report</Typography>
          <LabelValue label="Reason" value={report.reason} />
          <LabelValue label="Reason Type" value={report.reasonType} />
          <LabelValue label="Status" value={report.status} />
          <LabelValue
            label="Created At"
            value={Functions.DateFunctions.showDateInfo(report.createdAt)}
          />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Ad</Typography>
          <LabelValue label="ID" value={report.ad?._id} />
          <LabelValue label="Title" value={report.ad?.info?.title} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Ad Poster</Typography>
          <LabelValue label="ID" value={report.user?._id} />
          <LabelValue label="Title" value={report.user?.name} />

          <Typography sx={{ fontSize: 18, mt: 2 }}>Reporter</Typography>
          <LabelValue label="ID" value={report.reporter?._id} />
          <LabelValue label="Title" value={report.reporter?.name} />
        </Box>
      </Box>
    );
  }
};

export default ViewReport;