// React Imports
import React from "react";
import Dashboard from "../components/Dashboard";
import RoutePath from "./RoutePath";
import Functions from "../utils/Functions";

// Functional Imports

export const ROUTE = {
  ADMIN: "admin",
  EMPTY: "",
};

// Local Imports

const RouteSetter = () => {
  const JWT = Functions.LocalStorage.getString("JWT");
  const Type = Functions.LocalStorage.getString("Type");

  return <RoutePath.NoAuth />;
  // if (JWT !== "" && Type === ROUTE.ADMIN) {
  //   return <Dashboard />;
  // } else if (Type === ROUTE.EMPTY || JWT === "") {
  //   return <RoutePath.NoAuth />;
  // } else {
  //   return null;
  // }
};

export default RouteSetter;
