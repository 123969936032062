// React Imports
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

// MUI Imports

// Functional Imports

// Local Imports

interface LabelValueProps {
  label?: any;
  value?: any;
}

const LabelValue: FC<LabelValueProps> = (props) => {
  return (
    <Box
      sx={{
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        mb: 1,
        mt: 1,
      }}
    >
      <Typography sx={{ fontSize: 16, width: 160 }}>{props.label}</Typography>
      <Typography sx={{ fontSize: 16 }}>{props.value}</Typography>
    </Box>
  );
};

export default LabelValue;
