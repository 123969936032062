// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Typography, TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// Functional Imports
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import API from "../../api/API";

interface UserListProps {}

const UserList: FC<UserListProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    setDataLoading(true);
    API.userList(searchText).then((response) => {
      setDataLoading(false);
      setUserList(response.data);
    });
  };

  const onSearch = () => {
    getUserList();
  };

  return (
    <Box
      sx={{
        width: "80%",
        pt: 2,
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          User List
        </Typography>

        <TextField
          label="Search"
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{
            width: 300,
          }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={onSearch}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <DataGrid
        sx={{
          mt: 2,
        }}
        rows={userList}
        columns={columns}
        loading={dataLoading}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        getRowId={(row) => row._id}
        pageSizeOptions={[5]}
        filterMode="client"
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default UserList;

const columns: GridColDef[] = [
  { field: "_id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 150,
  },
  {
    field: "adsPosted",
    headerName: "Ads Posted",
    width: 150,
  },
  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
  },
];
