// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports

// Local Imports
import CText from "../../common/CText";

interface CategoryTileProps {
  image?: string;
  category?: string;
  sx?: SxProps;
}

const CategoryTile: FC<CategoryTileProps> = (props) => {
  return (
    <Box
      sx={{
        width: '15%',
        borderRadius: 2,
        mb: 2,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        ...props.sx,
      }}
    >
      <Box
        component={"img"}
        src={props.image}
        sx={{
          width: "100%",
        }}
      />
      <CText
        text={props.category}
        fontSize={20}
        fontWeight={500}
        sx={{
          mt: 2,
        }}
      />
    </Box>
  );
};

export default CategoryTile;
