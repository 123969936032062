// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Local Imports
import CText from "../../common/CText";

interface TeamTileProps {
  image?: string;
  name?: string;
  designation?: string;
  sx?: SxProps;
}

const TeamTile: FC<TeamTileProps> = (props) => {
  return (
    <Box
      sx={{
        height: 420,
        width: "30%",
        borderRadius: 2,
        mb: 2,
        backgroundColor: Colors.Grey3,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        ...props.sx,
      }}
    >
      <Box
        component={"img"}
        src={props.image}
        sx={{
          width: "100%",
        }}
      />
      <CText
        text={props.name}
        fontSize={24}
        fontWeight={600}
        sx={{
          width: "90%",
          mt: 2,
        }}
      />
      <CText
        text={props.designation}
        fontSize={20}
        fontWeight={400}
        sx={{
          width: "90%",
          mt: 1.5,
        }}
      />
    </Box>
  );
};

export default TeamTile;
