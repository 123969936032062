const SERVER = "https://www.server.scndspac.com/api";

const ENDPOINTS = {
  ADMIN: {
    LOGIN: SERVER + "/admin/login",
    VERIFY_OTP: SERVER + "/admin/verify-otp",
    USER_LIST: SERVER + "/admin/user-list",
    GET_USER: SERVER + "/admin/get-user",
    ALLOW_USER: SERVER + "/admin/allow-user",
    ADS_POSTED: SERVER + "/admin/ads-posted",
    GET_AD: SERVER + "/admin/get-ad",
    REPORT: SERVER + "/admin/report",
    REPORTS: SERVER + "/admin/reports",
  },
};

export default ENDPOINTS;
