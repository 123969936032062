// React Imports
import React, { FC, ReactNode } from "react";

// MUI Imports
import { Box, SxProps } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

// Functional Imports
import Colors from "../../utils/Colors";

// Local Imports
import CText from "../../common/CText";

interface FeatureTileProps {
  icon?: ReactNode;
  title?: string;
  subtitle?: string;
  sx?: SxProps;
}

const FeatureTile: FC<FeatureTileProps> = (props) => {
  return (
    <Box
      sx={{
        height: 290,
        width: "48%",
        borderRadius: 2,
        backgroundColor: Colors.Grey3,
        p: 5,
        py: 6,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        ...props.sx,
      }}
    >
      {props.icon}
      <CText fontSize={22} fontWeight={600} text={props.title} />
      <CText
        fontSize={18}
        fontWeight={400}
        color={Colors.Grey2}
        text={props.subtitle}
      />
    </Box>
  );
};

export default FeatureTile;
