// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import CallIcon from "@mui/icons-material/Call";
import AppsIcon from "@mui/icons-material/Apps";

// Functional Imports
import Colors from "../../utils/Colors";
import Images from "../../utils/Images";

// Local Imports
import CategoryTile from "./CategoryTile";
import QualityTile from "../../common/QualityTile";
import FeatureTile from "./FeatureTile";
import TeamTile from "./TeamTile";
import CText from "../../common/CText";
import { Link } from "react-router-dom";

interface LandingPageProps {}

const LandingPage: FC<LandingPageProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: Colors.Black,
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "85%",
          }}
        >
          <Box
            component={"img"}
            src={Images.ScndspacLogo}
            sx={{
              width: 250,
              mt: 8,
              ml: 10,
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            position: "relative",
          }}
        >
          <Box
            component={"img"}
            src={Images.LandingPageHero}
            sx={{ width: 600 }}
          />
          <CText
            text="SCNDSPAC is a used goods marketplace where you can sell products
        quickly and easily."
            color={Colors.White}
            fontSize={24}
            fontWeight={500}
            sx={{
              position: "absolute",
              bottom: 180,
              width: "60%",
              textAlign: "center",
            }}
          />
        </Box>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.scndspacmobile&pcampaignid=web_share"
        >
          <Box
            component={"img"}
            src={Images.GooglePlayRedirect}
            sx={{
              width: 300,
              border: "2px solid white",
              borderRadius: 2,
              boxShadow: "5px 10px #888888",
            }}
          />
        </a>

        <QualityTile
          title="Want to sell your things faster?"
          subtitle="Tired of unresponsive buyers and low ball offers, Try SCNDSPAC."
          image={Images.FirstQuality}
          leftOrRight="left"
        />
        <QualityTile
          title="Why should you pay for ads?"
          subtitle="Free ads creates a surplus of sellers and shortage of buyers, by paying for the ad, you can expect the buyers to be  more than the sellers."
          image={Images.SecondQuality}
          leftOrRight="right"
        />
        <QualityTile
          title="How much do ads cost?"
          subtitle="The ads are prices reasonable, 20 for mobiles, 25 for bikes, etc."
          image={Images.ThirdQuality}
          leftOrRight="left"
        />

        <CText
          text="Features"
          fontWeight={600}
          fontSize={44}
          sx={{
            width: "85%",
            my: 2,
          }}
        />

        <Box
          sx={{
            width: "85%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 3,
          }}
        >
          <FeatureTile
            title="Chat or Call with Sellers"
            subtitle="Chat and Call with buyers and sellers about products on the platform."
            icon={
              <CallIcon
                sx={{
                  color: Colors.White,
                  height: 40,
                  width: 40,
                }}
              />
            }
          />
          <FeatureTile
            title="Add to Favourites"
            subtitle="Like a product, but don't want to chat immediately, then add it to favourites."
            icon={
              <FavoriteBorderIcon
                sx={{
                  color: Colors.White,
                  height: 40,
                  width: 40,
                }}
              />
            }
          />
        </Box>

        <Box
          sx={{
            width: "85%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 3,
          }}
        >
          <FeatureTile
            title="Lots of different options"
            subtitle="Sell anything from camera to tables, and everything in between."
            icon={
              <AppsIcon
                sx={{
                  color: Colors.White,
                  height: 40,
                  width: 40,
                }}
              />
            }
          />
          <FeatureTile
            title="Ads based on location"
            subtitle="Ability to see list of all the ads of a particular category in a particular location."
            icon={
              <MyLocationIcon
                sx={{
                  color: Colors.White,
                  height: 40,
                  width: 40,
                }}
              />
            }
          />
        </Box>

        <CText
          text="Categories"
          fontWeight={600}
          fontSize={44}
          sx={{
            width: "85%",
            my: 3,
          }}
        />
        <Box
          sx={{
            width: "85%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CategoryTile image={Images.GadgetsIcon} category="Gadgets" />
          <CategoryTile image={Images.VehiclesIcon} category="Vehicles" />
          <CategoryTile image={Images.AppliancesIcon} category="Appliances" />
          <CategoryTile image={Images.PropertiesIcon} category="Properties" />
          <CategoryTile image={Images.FurnitureIcon} category="Furnitures" />
          <CategoryTile image={Images.ServicesIcon} category="Services" />
        </Box>

        <CText
          text="Team"
          fontWeight={600}
          fontSize={44}
          sx={{
            width: "85%",
            my: 2,
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TeamTile
            name="Prajwal S"
            designation="Research - Technology"
            image={Images.PrajwalFace}
          />
          <TeamTile
            name="Basavachetan M"
            designation="Development - Frontend"
            image={Images.BasuFace}
          />
          <TeamTile
            name="Sparsh M"
            designation="Development - Backend"
            image={Images.SparshFace}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <TeamTile
            name="Sahil M"
            designation="Development - Backend"
            image={Images.SahilFace}
          />
          <TeamTile
            name="Harish J"
            designation="UI/UX"
            image={Images.HarishFace}
          />
          <TeamTile
            name="Muskan J"
            designation="Development - Backend"
            image={Images.MuskanFace}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            width: 1200,
          }}
        >
          <TeamTile
            name="Shrikanth B"
            designation="Research - Business"
            image={Images.ShrikanthFace}
          />
          <Box
            sx={{
              height: 420,
              // width: 200,
              width: "30%",
            }}
          />
          <Box
            sx={{
              height: 420,
              width: "30%",
            }}
          />
        </Box>

        <CText
          text="Contact us at basugm000@gmail.com"
          fontWeight={600}
          fontSize={44}
          sx={{
            my: 4,
          }}
        />
      </Box>
    </Box>
  );
};

export default LandingPage;
