const ScndspacLogo = require("../assets/images/scndspac-logo.png");

const LandingPageHero = require("../assets/images/landing-page-hero.png");

const FirstQuality = require("../assets/images/first-quality.png");
const SecondQuality = require("../assets/images/second-quality.png");
const ThirdQuality = require("../assets/images/third-quality.png");

const BasuFace = require("../assets/images/basu-face.png");
const HarishFace = require("../assets/images/harish-face.png");
const MuskanFace = require("../assets/images/muskan-face.png");
const PrajwalFace = require("../assets/images/prajwal-face.png");
const SahilFace = require("../assets/images/sahil-face.png");
const ShrikanthFace = require("../assets/images/shrikanth-face.jpeg");
const SparshFace = require("../assets/images/sparsh-face.png");

const AppliancesIcon = require("../assets/icons/appliances-icon.png");
const FurnitureIcon = require("../assets/icons/furniture-icon.png");
const GadgetsIcon = require("../assets/icons/gadgets-icon.png");
const PropertiesIcon = require("../assets/icons/properties-icon.png");
const ServicesIcon = require("../assets/icons/services-icon.png");
const VehiclesIcon = require("../assets/icons/vehicles-icon.png");

const GooglePlayRedirect = require("../assets/images/google-play-redirect.png");

const Images = {
  ScndspacLogo,
  LandingPageHero,
  FirstQuality,
  SecondQuality,
  ThirdQuality,
  BasuFace,
  HarishFace,
  MuskanFace,
  PrajwalFace,
  SahilFace,
  ShrikanthFace,
  SparshFace,
  AppliancesIcon,
  FurnitureIcon,
  GadgetsIcon,
  PropertiesIcon,
  ServicesIcon,
  VehiclesIcon,
  GooglePlayRedirect,
};

export default Images;
