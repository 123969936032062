// React Imports
import { Button, SxProps } from "@mui/material";
import React, { FC } from "react";
import Colors from "../../utils/Colors";
import CText from "../CText";

// MUI Imports

// Functional Imports

// Local Imports

interface CButtonProps {
  title?: string;
  color?: string;
  onClick?: any;
  disabled?: boolean;
  sx?: SxProps;
}

const CButton: FC<CButtonProps> = (props) => {
  return (
    <Button
      variant="outlined"
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        width: "180px",
        height: "40px",
        textTransform: "none",
        backgroundColor: "transparent",
        border: "2px solid " + Colors.Gold,
        ":hover": {
          border: "2px solid " + Colors.Gold + "CC",
        },
        ...props.sx,
      }}
    >
      <CText
        fontSize={18}
        fontWeight={500}
        color={Colors.White}
        text={props.title}
      />
    </Button>
  );
};

export default CButton;
