// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, IconButton, Typography } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";

// Functional Imports
import API from "../../api/API";

// Local Imports
import LabelValue from "../../common/LabelValue";

interface UserProps {}

const User: FC<UserProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [user, setUser] = useState({
    _id: "",
    mobileNumber: "",
    name: "",
    profileImage: "",
    walletBalance: 0,
    adsPosted: 0,
    adminAllow: false,
    email: "",
  });

  useEffect(() => {
    setDataLoading(true);
    API.getUser("65a13e958df5e35d8d3823a3").then((response) => {
      setDataLoading(false);
      setUser(response.user);
    });
  }, []);

  const onToggle = () => {
    setDataLoading(true);
    API.toggleAdminAllow("65a13e958df5e35d8d3823a3").then((response) => {
      setDataLoading(false);
      setUser({
        ...user,
        adminAllow: !user.adminAllow,
      });
    });
  };

  if (dataLoading) {
    return (
      <Box
        sx={{
          width: "80%",
          pt: 2,
          px: 2,
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Loading...
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "80%",
          height: "90vh",
          pt: 2,
          px: 2,
          overflowY: "scroll",
        }}
      >
        <Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            User
          </Typography>

          <LabelValue label="ID" value={user._id} />
          <LabelValue label="Name" value={user.name} />
          <LabelValue label="Wallet Balance" value={user.walletBalance} />
          <LabelValue label="Mobile Number" value={user.mobileNumber} />
          <LabelValue label="Email" value={user.email} />
          <LabelValue label="Ads Posted" value={user.adsPosted} />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <LabelValue
              label="Admin Allow"
              value={user.adminAllow ? "Yes" : "No"}
            />
            <IconButton onClick={onToggle} sx={{ ml: 2 }}>
              <AutorenewIcon />
            </IconButton>
          </Box>
          <Typography sx={{ fontSize: 16 }}>Profile Image</Typography>
          <Box
            component={"img"}
            src={user.profileImage}
            sx={{
              height: 300,
              width: 300,
              mt: 2,
            }}
          />
        </Box>
      </Box>
    );
  }
};

export default User;
