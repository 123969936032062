import { Box, TextField, TextFieldProps } from "@mui/material";
import CText from "../CText";
import { FC } from "react";
import Colors from "../../utils/Colors";

interface LabelInputProps {
  label?: any;
  textFieldProps?: TextFieldProps;
  sx?: any;
  textFieldSx?: any;
}

const LabelInput: FC<LabelInputProps> = (props) => {
  return (
    <Box sx={{ mt: 2, width: "90%", ...props.sx }}>
      <CText text={props.label} fontSize={18} />
      <TextField
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        onWheel={(event) => {
          event.preventDefault();
        }}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: Colors.White,
          },
          width: "100%",
          height: "50px",
          justifyContent: "center",
          paddingLeft: 1,
          borderRadius: "3px",
          borderWidth: 0,
          backgroundColor: Colors.Grey3,
          mt: 1,
          input: { color: Colors.White },
          ...props.textFieldSx,
        }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

export default LabelInput;
