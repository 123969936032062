// React Imports
import React, { FC } from "react";

// MUI Imports
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Colors from "../../utils/Colors";

// Functional Imports

// Local Imports
interface SideBarTileProps {
  label?: any;
  icon?: any;
  selected?: any;
}

const SideBarTile: FC<SideBarTileProps> = (props) => {
  return (
    <ListItem
      sx={{
        backgroundColor: props.selected ? Colors.Grey : Colors.White,
      }}
      disablePadding
    >
      <ListItemButton>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItemButton>
    </ListItem>
  );
};

export default SideBarTile;
