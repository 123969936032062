import moment from "moment";

const setString = (label: any, value: any) => {
  localStorage.setItem(label, value);
};

const getString = (label: any): string => {
  const value = localStorage.getItem(label);
  if (value === null) {
    return "";
  } else {
    return value;
  }
};

const LocalStorage = {
  getString,
  setString,
};

const showDateInfo = (date: any, format: string = "DD-MM-YYYY") => {
  const isValid = moment(date).isValid();
  if (isValid) {
    return moment(date).format(format);
  } else {
    return "-";
  }
};

const DateFunctions = {
  showDateInfo,
};

const Functions = {
  LocalStorage,
  DateFunctions,
};

export default Functions;
