// React Imports
import { Box } from "@mui/material";
import React, { FC } from "react";
import Colors from "../../utils/Colors";
import Images from "../../utils/Images";
import CText from "../../common/CText";
import LabelInput from "../../common/LabelInput";
import CButton from "../../common/CButton";

// MUI Imports

// Functional Imports

// Local Imports

interface AccountDeleteProps {}

const AccountDelete: FC<AccountDeleteProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: Colors.Black,
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "95%",
          }}
        >
          <Box
            component={"img"}
            src={Images.ScndspacLogo}
            sx={{
              width: 250,
              mt: 8,
              ml: 10,
            }}
          />
        </Box>

        <Box
          sx={{
            width: "85%",
          }}
        >
          <CText
            text="Delete Account"
            fontWeight={600}
            fontSize={36}
            sx={{
              width: "85%",
              my: 4,
            }}
          />

          <LabelInput
            sx={{
              width: 400,
            }}
            label="Phone Number"
            textFieldProps={{
              placeholder: "Enter phone number",
            }}
          />

          <LabelInput
            sx={{
              width: 400,
            }}
            label="OTP"
            textFieldProps={{
              placeholder: "Enter OTP",
            }}
          />

          <LabelInput
            sx={{
              width: 400,
            }}
            label="Reason"
            textFieldProps={{
              placeholder: "Enter Reason",
            }}
          />

          <CButton
            title="Confirm"
            sx={{
              mt: 2,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AccountDelete;
